import  "./header.css";

export default function Header() {
  return (
    <div className='header'>
      <div className="headerTitles">
        <span className="headerTitleSm">My Blog</span>
        <span className="headerTitleLg"></span>
      </div>
<img className="headerImg"
 src="https://images.pexels.com/photos/4040861/pexels-photo-4040861.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
alt="scnery"></img>


    </div>
  )
}
